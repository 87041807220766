<script setup lang="ts">
import { Promotion } from "~/types";

const { t } = useT();

defineProps<{
	enabled?: boolean;
	image: string;
	type?: Promotion["type"];
	text?: string;
}>();

const isAnimation = ref(false);
const dailyWheel = ref<HTMLElement | null>(null);

const emit = defineEmits<{ (event: "click"): void }>();
const handleClick = () => {
	emit("click");
};

onMounted(() => {
	const observer = new IntersectionObserver(
		(el) => {
			isAnimation.value = el[0].isIntersecting;
		},
		{ threshold: 0.7 }
	);
	if (dailyWheel.value) {
		observer.observe(dailyWheel.value);
	}
});
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 292,
			height: 380,
			src: image,
			format: 'webp',
			loading: 'lazy'
		}"
		width="292px"
		height="380px"
		:class="[`card-${type}`]"
		@click="handleClick"
	>
		<template #top>
			<NuxtImg
				src="/nuxt-img/wheel-promo/title.png"
				width="500"
				height="200"
				class="title-img"
				alt="title"
				loading="lazy"
			/>
			<div class="text">
				<AText variant="tanta" as="h3" :modifiers="['uppercase', 'center', 'nowrap']" class="title">
					{{ t("Rewards every day!") }}
				</AText>
				<AText variant="toledo" as="p" :modifiers="['bold', 'center']" class="subtitle">{{ text }}</AText>
			</div>
		</template>
		<template #bottom>
			<div ref="dailyWheel" class="box" :class="{ 'animation-active': isAnimation }">
				<NuxtImg
					class="arrow"
					src="/nuxt-img/wheel-promo/arrow.png"
					format="webp"
					width="88"
					height="120"
					alt="arrow"
					loading="lazy"
				/>
				<NuxtImg
					class="wheel"
					src="/nuxt-img/wheel-promo/wheel.png"
					format="webp"
					width="891"
					height="901"
					alt="wheel"
					loading="lazy"
				/>
				<NuxtImg
					class="drum"
					src="/nuxt-img/wheel-promo/circle@2x.png"
					format="webp"
					width="725"
					height="724"
					alt="drum"
					loading="lazy"
				/>
				<NuxtImg
					class="sector"
					src="/nuxt-img/wheel-promo/sector.png"
					format="webp"
					width="652"
					height="651"
					alt="sector"
					loading="lazy"
				/>
				<NuxtImg
					class="spin"
					src="/nuxt-img/wheel-promo/spin@2x.png"
					format="webp"
					width="206"
					height="206"
					alt="spin"
					loading="lazy"
				/>
			</div>
			<NuxtImg
				src="/nuxt-img/wheel-promo/leaves@2x.png"
				width="584"
				height="392"
				class="leaves"
				alt="leaves"
				loading="lazy"
			/>

			<AButton variant="primary" size="xl" class="foot">
				<AText variant="turin" :modifiers="['medium']"> {{ t("Play Now!") }} </AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.title-img {
	max-width: 162px;
	width: 250px;
	height: auto;
}

.text {
	display: flex;
	flex-direction: column;
	gap: gutter(0.5);
	align-items: center;
}

.title {
	text-shadow:
		0 4px 6px rgba(var(--corlu-rgb), 0.15),
		0 10px 15px rgba(var(--corlu-rgb), 0.21);
	line-height: 26px;
}

.subtitle {
	text-shadow:
		0 4px 6px rgba(var(--corlu-rgb), 0.15),
		0 10px 15px rgba(var(--corlu-rgb), 0.21);
	line-height: 20px;
}

.foot {
	width: 100%;
	position: relative;
	z-index: 2;
}

.leaves {
	width: 292px;
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: auto;
	z-index: 2;
}

.arrow {
	position: absolute;
	width: 30px;
	height: auto;
	top: -7px;
	left: 50%;
	transform: translateX(calc(-50% - 2px));
	z-index: 2;
}

.spin {
	display: block;
	position: absolute;
	bottom: 17px;
	left: 50%;
	transform: translateX(-50%);
	width: 85px;
	height: auto;
	z-index: 2;
}

.drum,
.wheel {
	position: absolute;
	left: 50%;
	width: calc(100% - 48px);
	bottom: 0;
	transform: translate(-50%, 25%);
	height: auto;
	z-index: -1;
}

.sector {
	width: 215px;
	height: auto;
	position: absolute;
	top: -24px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.wheel {
	z-index: 0;
}

.box {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;

	&::before {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: 20px;
		right: 20px;
		height: 122px;
		background: var(--gebze);
		border-radius: 12px;
		z-index: 2;
	}
}

.animation-active {
	.wheel {
		animation: animation-drum 16.5s infinite;
	}
}

@keyframes animation-drum {
	0%,
	100% {
		transform: translate(-50%, 25%) rotate(0);
	}
	34% {
		transform: translate(-50%, 25%) rotate(730deg);
	}
	39.3939%,
	99.9999% {
		transform: translate(-50%, 25%) rotate(720deg);
	}
}
</style>
